import {useEffect, useState} from 'react';

import {
  Avatar,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useBoolean,
} from '@chakra-ui/react';

import {getOrganizationById} from '../services/organizationsService';
import {Organization} from '../types/organizations';
import {OrganizationPickerList} from './OrganizationPickerList';

interface OrganizationPickerButtonProps {
  idOrganization: string;
}

export function OrganizationPickerButton(props: OrganizationPickerButtonProps) {
  const {idOrganization} = props;

  const [organization, setOrganization] = useState<Organization>();
  const [isLoading, setIsLoading] = useState(true);
  const [isModalOpen, modalState] = useBoolean();

  useEffect(() => {
    if (!idOrganization) {
      return;
    }
    setIsLoading(true);
    getOrganizationById(idOrganization)
      .then(setOrganization)
      .catch(console.error)
      .finally(() => setIsLoading(false));
  }, [idOrganization]);

  return (
    <>
      <Button
        padding={5}
        rounded='full'
        variant='outline'
        colorScheme='teal'
        isLoading={isLoading}
        onClick={modalState.on}
        leftIcon={<Avatar size='sm' name={organization?.name} />}>
        {organization?.name || 'Cargando...'}
      </Button>
      <Modal isOpen={isModalOpen} onClose={modalState.off}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader mt={5}>Selecciona una organización</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <OrganizationPickerList />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
