import {useMemo, useState} from 'react';

import {
  Avatar,
  Center,
  HStack,
  Input,
  InputGroup,
  InputLeftElement,
  Spinner,
  Text,
  VStack,
} from '@chakra-ui/react';

import {SearchIcon} from '@chakra-ui/icons';
import {Link as RouterLink, useLocation} from 'react-router-dom';

import {useOrganizations} from '../hooks/useOrganizations';
import {Card} from './Card';

export function OrganizationPickerList() {
  const organizations = useOrganizations();
  const {pathname} = useLocation();
  const [searchString, setSearchString] = useState<string>('');
  const pathSuffix =
    pathname !== '/' ? pathname.split('/').slice(2).join('/') : 'home';

  const filteredOrganizations = useMemo(() => {
    return organizations.data.filter(org =>
      org.name.toLowerCase().includes(searchString.toLowerCase()),
    );
  }, [organizations, searchString]);

  if (organizations.isLoading) {
    return (
      <Center>
        <Spinner />
      </Center>
    );
  }

  return (
    <VStack mt={5}>
      <InputGroup mb={5}>
        <InputLeftElement pointerEvents='none'>
          <SearchIcon />
        </InputLeftElement>
        <Input
          variant='outline'
          value={searchString}
          placeholder='Buscar organización'
          onChange={evt => setSearchString(evt.target.value)}
        />
      </InputGroup>
      <VStack maxHeight={500} w='100%' overflowY='auto' p={5}>
        {filteredOrganizations.map(org => (
          <Card
            as={RouterLink}
            key={org.idOrganization}
            _hover={{bg: 'gray.100'}}
            to={`/${org.idOrganization}/${pathSuffix}`}
            justifyContent='left'>
            <HStack justifyContent='flex-start'>
              <Avatar name={org.name} />
              <Text>{org.name}</Text>
            </HStack>
          </Card>
        ))}
      </VStack>
    </VStack>
  );
}
